import localFont from "next/font/local";
import { Lato } from "next/font/google";
import "../styles/sass/index.scss";
import { gsiScript } from "../util/utils";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Analytics from "@components/atoms/document/Analytics";

export const LatoFont = Lato({
  subsets: ["latin"],
  display: "swap",
  weight: ["400", "700"],
  variable: "--lato",
});

export const EuclidCircularSemibold = localFont({
  src: "../fonts/EuclidCircularB-Semibold.woff",
  variable: "--Euclid-Circular-B",
  weight: "600",
  preload: true,
  display: "swap",
  style: "normal",
  fallback: ["arial"],
  adjustFontFallback: false,
});

export const Inter = localFont({
  src: "../fonts/InterFont.woff2",
  variable: "--Inter",
  preload: true,
  display: "swap",
  style: "normal",
  fallback: ["arial"],
  adjustFontFallback: false,
});

function MyApp(props) {
  let { Component, pageProps } = props;
  const router = useRouter();
  useEffect(() => {
    router.events.on("routeChangeComplete", (path) => {
      gsiScript();
    });
    return () => {
      router.events.off("routeChangeComplete", 0);
    };
  }, [router.events]);
  return (
    <>
      <Analytics nonce={pageProps.nonce} />
      <Component {...pageProps} />
    </>
  );
}

MyApp.getInitialProps = (appContext) => {
  const nonce = appContext.ctx.req?.headers["x-nonce"] || "";
  return { pageProps: { nonce } };
};

export default MyApp;
