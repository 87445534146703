import Script from "next/script";

export default function Analytics({ nonce }: { nonce: string }) {
  return (
    <>
      {process.env.APP_MODE === "live" ? (
        <Script
          id="analytics-script"
          strategy="afterInteractive"
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `function gtmCode() {
              (function (a, b, c, d, e) {
              (a[d] = a[d] || []),
              a[d].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
              var g = b.getElementsByTagName(c)[0],
              f = b.createElement(c),
              h = "dataLayer" == d ? "" : "&l=" + d;
              (f.async = !0),
              (f.src = "https://www.googletagmanager.com/gtm.js?id=" + e + h),
              g.parentNode.insertBefore(f, g);
              })(window, document, "script", "dataLayer", "GTM-5H66N96");
            }
            setTimeout(gtmCode, 4000);`,
          }}
        ></Script>
      ) : (
        <Script
          id="analytics-script"
          strategy="afterInteractive"
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `function gtmCode() {
            (function (a, b, c, d, e) {
            (a[d] = a[d] || []),
            a[d].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var g = b.getElementsByTagName(c)[0],
            f = b.createElement(c),
            h = "dataLayer" == d ? "" : "&l=" + d;
            (f.async = !0),
            (f.src = "https://www.googletagmanager.com/gtm.js?id=" + e + h),
            g.parentNode.insertBefore(f, g);
            })(window, document, "script", "dataLayer", "GTM-KZS5F96");
          }
          setTimeout(gtmCode, 4000);`,
          }}
        ></Script>
      )}
    </>
  );
}
